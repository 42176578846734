import React from "react";
import "./App.css";
import "./styles/components/Navbar.css";
import Navbar from "./components/Navbar";
import HomePage from "./pages/HomePage";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useTranslation } from "react-i18next";
import PrestaPage from "./pages/PrestaPage";
import PageGCU from "./pages/PageGCU";
import PageGMA from "./pages/PageGMA";
import PageTELECOM from "./pages/PageTELECOM";
import PageIOT from "./pages/PageIOT";
import PageDEVLOG from "./pages/PageDEVLOG";
import PageDEVWEB from "./pages/PageDEVWEB";
import PageDATA from "./pages/PageDATA";
import PageQSN from "./pages/PageQSN";
import { images } from './image';
import ErrorBoundary from "./components/ErrorBoundary";

export default function App() {
  const { t } = useTranslation('presta');

  const domaines = [
    {
      id: 1,
      nom: t('gma'),
      page: <PageGMA />,
      img: images.hexa_meca,
      imgHover: images.hexableu_meca
    },
    {
      id: 2,
      nom: t('gcu'),
      page: <PageGCU />,
      img: images.hexa_geniecivil,
      imgHover: images.hexableu_geniecivil
    },
    {
      id: 3,
      nom: t('telecom'),
      page: <PageTELECOM />,
      img: images.hexa_telecom,
      imgHover: images.hexableu_telecom
    },
    {
      id: 4,
      nom: t('iot'),
      page: <PageIOT />,
      img: images.hexa_iot,
      imgHover: images.hexableu_iot
    },
    {
      id: 5,
      nom: t('devlog'),
      page: <PageDEVLOG />,
      img: images.hexa_software,
      imgHover: images.hexableu_software
    },
    {
      id: 6,
      nom: t('devweb'),
      page: <PageDEVWEB />,
      img: images.hexa_web,
      imgHover: images.hexableu_web
    },
    {
      id: 7,
      nom: t('data'),
      page: <PageDATA />,
      img: images.hexa_data,
      imgHover: images.hexableu_data
    },
  ];

  return (
    <div style={{ position: "relative" }}>
      <BrowserRouter>
      <Navbar />
      <ErrorBoundary>
        <Routes>
          <Route path="/" element={<HomePage />} />
            <Route path="/prestations" element={<PrestaPage domaines={domaines} />} />
          <Route path="/team" element={<PageQSN />} />
        </Routes>
        </ErrorBoundary>
      </BrowserRouter>
    </div>
  );
}
