import React from 'react';
import '../styles/pages/pagesSpec.css';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';

export default function PageDATA() {
  const { t } = useTranslation('presta');
  const classes = useStyles();
  const [value, setValue] = React.useState(0); // Modification de la valeur initiale

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div>
      <div className='title'>{t('data')}</div>
      <div className='textdesc'>{t('data_desc')}</div>
      <div className='tab'>
        <Box display='flex' justifyContent='center' width='100%'>
          <Tabs
            value={value}
            variant="fullWidth"
            onChange={handleChange}
            centered
            TabIndicatorProps={{
              style: {
                backgroundColor: "#3cacb6"
              }
            }}>
            <Tab label={<span className={value === 0 ? classes.activeTab : classes.customStyleOnTab}>{t ('realisations')}</span>} />
            <Tab label={<span className={value === 1 ? classes.activeTab : classes.customStyleOnTab}>{t ('formation')}</span>} />
          </Tabs>
        </Box>
      </div>
      <br></br>
      <div className="tabpanel">
        {value === 0 && (
          <div className='textdesc'>
            <ul id="maListe">
            {t('datareal', { returnObjects: true }).map((item, index) => (
                <li key={index}>{item}</li>
              ))}
          </ul>
        </div>
        )}
        {value === 1 && (
          <div className='textdesc'> {t('infoforma')}</div>
        )}
      </div>
    </div>
  );
}

const useStyles = makeStyles({
  customStyleOnTab: {
    fontSize: 'large',
    color: 'beige'
  },
  customStyleOnActiveTab: {
    color: 'red'
  },
  activeTab: {
    fontSize: 'x-large',
    fontWeight: '600',
    color: '#3cacb6'
  }
});
