import React, {useState} from 'react';
import{motion} from "framer-motion";
import "../styles/components/PrestaButton.css";

export default function PrestaButton(props) {
    // state (état, données)
    
    let [imgSrc, setImgSrc] = useState(props.img);

    const handleImgMouseEnter = () => {
        setImgSrc(props.imgHover);
    };

    const handleImgMouseLeave = () => {
        setImgSrc(props.img);
    };


    const screenWidth = window.innerWidth;
    const screenHeight = window.screen.height - 125; 

    const initialStates = [
        // Pourcentage sur la largeur (x) et la hauteur (y)
        {x:0.5, y: 0.22, scale: 1.2},
        {x:0.66, y: 0.29, scale: 1.2},
        {x:0.78, y: 0.50, scale: 1.2},
        {x:0.82, y: 0.77, scale: 1.2},
        {x:0.18, y: 0.77, scale: 1.2},
        {x:0.22, y: 0.50, scale: 1.2},
        {x:0.34, y: 0.29, scale: 1.2},
        {x:(50+(props.id-1)*100)/screenWidth, y: 0, scale: 1.8}
    ];
    const states = [
        // Pourcentage sur la largeur (x) et la hauteur (y)
        {x:0.20, y: 0.61, scale: 0.5},
        {x:0.27, y: 0.59, scale: 0.8},
        {x:0.37, y: 0.55, scale: 1.2},
        {x:0.5, y: 0.505, scale: 1.5},
        {x:0.63, y: 0.55, scale: 1.2},
        {x:0.73, y: 0.59, scale: 0.8},
        {x:0.8, y: 0.61, scale: 0.5},
        {x:(50+(props.id-1)*100)/screenWidth, y: 0, scale: 1.8}
    ];

    const state = {
        x: props.initial?initialStates[props.state].x*screenWidth-(50+(props.id-1)*100):states[props.state].x*screenWidth-(50+(props.id-1)*100),
        y: props.initial?initialStates[props.state].y*screenHeight:states[props.state].y*screenHeight,
        scale: props.initial?initialStates[props.state].scale:states[props.state].scale,
        opacity: !props.initial && (props.state===0||props.state===6)?0:1
    };



    // comportements

    // affichage (render)
        return(
            <motion.img 
            src={imgSrc}
            onMouseEnter={handleImgMouseEnter}
            onMouseLeave={handleImgMouseLeave}
            height="84" 
            width="100" 
            onClick={!props.initial&&(props.state===0||props.state===6)?handleImgMouseLeave:props.onClick} 
            initial={{
                x: state.x,
                y: state.y-100,
                scale: state.scale
            }}
            animate={{
                x: state.x,
                y: state.y-100,
                scale: state.scale,
                opacity: state.opacity
            }}
            transition={{
                type: "spring",
                damping: 20
            }}
            whileHover={{
                scale: props.active?states[props.state].scale:(props.initial?initialStates[props.state].scale*1.2:states[props.state].scale*1.2) 
            }}
            whileTap={{
                scale: props.initial?1.2:states[props.state].scale*0.9
            }}>
            </motion.img>
        );
    
}