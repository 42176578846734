import React from "react";
import "../styles/components/AnimatedArrowStyles.css";
import { NavLink } from "react-router-dom";
import "../styles/components/PlusButtonStyles.css";

export default function PlusButton() {
  return (
    <NavLink to="/prestations" className="nav">
      <div className="animated-arrow-containerplus">+</div>
    </NavLink>
  );
}
