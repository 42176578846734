import React from "react";
import "../styles/components/ContactUsButtonStyles.css";
import { useTranslation } from "react-i18next";

export default function ContactUsButton(props) {
  const { t } = useTranslation("navbfoot");
  return (
    <a href="#divOne" className="contact-us-button">
      <button className="btn-nous-contacter">{t('Contact')}</button>
    </a>
  );
}
