import React, { useRef } from "react";
import { Parallax, ParallaxLayer } from "@react-spring/parallax";
import AnimatedArrow from "../components/AnimatedArrow";
import "../styles/pages/HomePageStyles.css";
import SpaceBackground from "../images/background_images/space_background.webp";
import SkyBackground from "../images/background_images/sky_background.webp";
import FontEtoile1 from "../images/background_images/fond_etoile_1.webp";
import FontEtoile2 from "../images/background_images/fond_etoile_2.webp";
import FontEtoile3 from "../images/background_images/fond_etoile_3.webp";
import Clouds1 from "../images/background_images/clouds1.webp";
import Clouds2 from "../images/background_images/clouds2.webp";
import Clouds3 from "../images/background_images/clouds3.webp";
import Clouds4 from "../images/background_images/clouds4.webp";
import Clouds6 from "../images/background_images/clouds6.webp";
import Clouds7 from "../images/background_images/clouds7.webp";
import MarsPlanet from "../images/background_images/mars_planet.webp";
import SaturnPlanet from "../images/background_images/saturn_planet.webp";
import PopulatedPlanet from "../images/background_images/populated_planet.webp";
import HotAirBalloon from "../images/background_images/hot_air_balloon.webp";
import Astronaut from "../animations/astronaut.json";
import Sattelite from "../animations/sattelite.json";
import Mechanism from "../animations/mechanism.json";
import Code from "../animations/code_animation.json";
import ElectronicAnimation from "../animations/electronic_animation.json";
import Lottie from "lottie-react";
import PlusButton from "../components/plusButton";
import InsaFonce from "../images/background_images/insa_fonce.webp";
import ContactUsButton from "../components/ContactUsButton";
import { useTranslation } from "react-i18next";
import PageFooter from "../components/Page-Footer";

const pageNumber = 6;
const MemoizedPageFooter = React.memo(PageFooter);

export default function App() {
  const { t } = useTranslation("accueil");
  const parallax = useRef(Parallax);
  return (
    <div
      style={{ width: "100%", height: "100vh", background: "var(--mainColor)" }}
    >
      <Parallax ref={parallax} pages={pageNumber}>
        {/* Ouest insa title page  */}

        {/* space background */}
        <ParallaxLayer
          offset={0}
          speed={0}
          factor={3}
          style={{
            width : "100%",
            height: "100%",
            opacity: "0.5",
          }}
        >
          <img className="space-background" src={SpaceBackground} alt="space-background" loading = "lazy"/>
        </ParallaxLayer>

        {/* sky backgrounds */}
        <ParallaxLayer
          offset={3}
          speed={0}
          factor={3}
          style={{
            width: "100%",
            height: "100%",
            opacity: "1",
          }}
        >
          <img className="sky-background" src={SkyBackground} alt="sky-background" loading = "lazy"/>
        </ParallaxLayer>

        <ParallaxLayer
          offset={5}
          speed={0}
          factor={10}
          style={{
            width: "100%",
            height: "100%",
          }}
        >
          <img className="sky-background-full" src={SkyBackground} alt="sky-background" loading = "lazy"/>
        </ParallaxLayer>

        {/* fond d'étoile 1 */}
        <ParallaxLayer
          offset={0}
          speed={-0.5}
          style={{
            width: "100%",
            height: "100%",
          }}
        >
          <img className="font-etoile-1" src={FontEtoile1} alt="font-etoile-1" loading = "lazy"/>
        </ParallaxLayer>
        {/* fond d'étoile 2 */}
        <ParallaxLayer
          offset={1}
          speed={0.7}
          style={{
            width: "100%",
            height: "100%",
          }}
        >
          <img className="font-etoile-2" src={FontEtoile2} alt="font-etoile-2" loading = "lazy"/>
        </ParallaxLayer>
        {/* fond d'étoile 3 */}
        <ParallaxLayer
          offset={2}
          speed={0.6}
          style={{
            width: "100%",
            height: "100%",
            opacity: "0.5",
          }}
        >
          <img className="font-etoile-3" src={FontEtoile3} alt="font-etoile-3" loading = "lazy"/>
        </ParallaxLayer>

        <ParallaxLayer
          offset={1.5}
          speed={0.4}
          style={{
            width: "100%",
            height: "100%",
          }}
        >
          <img className="font-etoile-3" src={FontEtoile3} alt="font-etoile-3" loading = "lazy"/>
        </ParallaxLayer>

        {/* clouds 1 */}
        <ParallaxLayer
          offset={3}
          speed={-0.2}
        >
          <img className="clouds1" src={Clouds1} alt="clouds1" loading = "lazy"/>
        </ParallaxLayer>
        {/* clouds 2 */}
        <ParallaxLayer
          offset={4.2}
          speed={0.2}
        >
          <img className="clouds2" src={Clouds2} alt="clouds2" loading = "lazy"/>
        </ParallaxLayer>
        {/* clouds 3 */}
        <ParallaxLayer
          offset={3.4}
          speed={0.1}
        >
          <img className="clouds3" src={Clouds3} alt="clouds3" loading = "lazy"/>
        </ParallaxLayer>
        {/* clouds 4 */}
        <ParallaxLayer
          offset={4.3}
          speed={0.4}
        >
          <img className="clouds4" src={Clouds4} alt="clouds4" loading = "lazy"/>
        </ParallaxLayer>
        {/* clouds 6 */}
        <ParallaxLayer
          offset={5}
          speed={-0.4}
        >
          <img className="clouds6" src={Clouds6} alt="clouds6" loading = "lazy"/>
        </ParallaxLayer>

        {/* clouds 7 */}
        <ParallaxLayer
          offset={4.2}
          speed={-0.2}
        >
          <img className="clouds7" src={Clouds7} alt="clouds7" loading = "lazy"/>
        </ParallaxLayer>

        {/* clouds 1 bis */}
        <ParallaxLayer
          offset={5.2}
          speed={0.2}
        >
          <img className="clouds1" src={Clouds1} alt="clouds1" loading = "lazy"/>
        </ParallaxLayer>
        {/* clouds 2 bis */}
        <ParallaxLayer
          offset={3}
          speed={0}
        >
          <img className="clouds2" src={Clouds2} alt="clouds2" loading = "lazy"/>
        </ParallaxLayer>
        {/* clouds 3 bis */}
        <ParallaxLayer
          offset={5}
          speed={-0.3}
        >
          <img className="clouds3" src={Clouds3} alt="clouds3" loading = "lazy"/>
        </ParallaxLayer>

        {/* clouds 6 bis */}
        <ParallaxLayer
          offset={4.5}
          speed={0.4}
          style={{
          }}
        >
          <img className="clouds6" src={Clouds6} alt="clouds6" loading = "lazy"/>
        </ParallaxLayer>

        {/* hot air balloon */}
        <ParallaxLayer
          offset={4.7}
          speed={0.2}
          style={{
            backgroundImage: `url()`,
            backgroundSize: "5%",
          }}
        >
          <img
            className="hot-air-balloon"
            src={HotAirBalloon}
            alt="hot air balloon"
            loading = "lazy"
          />
        </ParallaxLayer>

        {/* Populated planet */}
        <ParallaxLayer offset={0} speed={-0.2} style={{}}>
          <img
            className="populated-planet"
            src={PopulatedPlanet}
            alt="populated planet"
          />
        </ParallaxLayer>

        {/* Astronaut */}
        <ParallaxLayer
          offset={0.5}
          speed={0.8}
          style={{
            zIndex: 0,
            width: "10%",
            marginLeft: "70%",
          }}
        >
          <Lottie className="astronaut" animationData={Astronaut}></Lottie>
        </ParallaxLayer>

        {/* Vos projets d'aujourd'hui, par les ingénieurs de demain  */}
        <ParallaxLayer
          offset={0}
          speed={-0.2}
          onClick={() => {
            parallax.current.scrollTo(1);
          }}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <h2 style={{ cursor: "pointer" }}>
            {t('Sloganp1')}
            <br />
            {t('Sloganp2')}
          </h2>
          <AnimatedArrow down={true} />
        </ParallaxLayer>

        {/* Ouest INSA, Junior-Entreprise de l'INSA Rennes */}
        <ParallaxLayer
          offset={1}
          speed={0.4}
          onClick={() => {
            parallax.current.scrollTo(2);
          }}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <h1 style={{ cursor: "pointer" }}>
            Ouest INSA,
            <br />
            {t('JEfromINSA')}
          </h1>
          <AnimatedArrow down={true} />
        </ParallaxLayer>

        {/* Sattelite */}
        <ParallaxLayer
          offset={1.6}
          speed={0.2}
          style={{
            zIndex: 10,
            display: "block",
            width: "20%",
            marginLeft: "10%",
          }}
        >
          <Lottie animationData={Sattelite} className="sattelite"> </Lottie>
        </ParallaxLayer>

        {/* Saturn */}
        <ParallaxLayer offset={2} speed={-0.2} style={{}}>
          <img className="saturn-planet" src={SaturnPlanet} alt="Saturn" loading = "lazy"/>
          {/* lazy loading */}

        </ParallaxLayer>

        {/* Mars */}
        <ParallaxLayer offset={2.1} speed={0.45} style={{}}>
          <img className="mars-planet" src={MarsPlanet} alt="Mars" loading = "lazy"/>
        </ParallaxLayer>

        {/* Un besoin en ingénierie ? */}
        <ParallaxLayer
          offset={2}
          speed={0.35}
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            zIndex: 6,
            height: "60px",
            marginTop: "5vh",
          }}
          onClick={() => {
            parallax.current.scrollTo(1);
          }}
        >
          <AnimatedArrow down={false} />
        </ParallaxLayer>

        <ParallaxLayer
          offset={2}
          speed={0.4}
          style={{
            display: "flex",
            justifyContent: "center",
            zIndex: 5,
            marginTop: "30vh",
          }}
        >
          <h3>{t('Besoin')}</h3>
        </ParallaxLayer>

        <ParallaxLayer
          offset={2}
          speed={0.5}
          style={{
            marginTop: "44vh",
            paddingRight: "40vw",
          }}
          onClick={() => {
            parallax.current.scrollTo(3);
          }}
        >
          <h4>
            {t('Nous sommes')} <span className="redText">{t('généralistes')} </span>
          </h4>
        </ParallaxLayer>
        <ParallaxLayer
          offset={2}
          speed={0.7}
          style={{
            marginTop: "56vh",
          }}
          onClick={() => {
            parallax.current.scrollTo(3);
          }}
        >
          <h4>
            {t('Nous sommes')}  <span className="redText">{t('créatifs')}</span>
          </h4>
        </ParallaxLayer>
        <ParallaxLayer
          offset={2}
          speed={0.9}
          style={{
            marginTop: "68vh",
            paddingLeft: "40vw",
          }}
          onClick={() => {
            parallax.current.scrollTo(3);
          }}
        >
          <h4>
            {t('Nous sommes')}  <span className="redText">{t('passionnés')} </span>
          </h4>
        </ParallaxLayer>

        <ParallaxLayer
          offset={2}
          speed={0.2}
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            zIndex: 6,
            height: "60px",
            marginTop: "80vh",
          }}
          onClick={() => {
            parallax.current.scrollTo(3);
          }}
        >
          <AnimatedArrow down={true} />
        </ParallaxLayer>

        {/* les 3 spécialités */}
        <ParallaxLayer offset={3} speed={0.5} className="specialites-container">
          <h4 className="mecanique">{t('Méca')} </h4>
          <h4 className="informatique">{t('Info')} </h4>
          <h4 className="electronique">{t('Elec')} </h4>
        </ParallaxLayer>

        <ParallaxLayer
          offset={3}
          speed={0.4}
          className="specialites-container-animations"
        >
          <Lottie className="mecanique" animationData={Mechanism}>
          </Lottie>
          <Lottie className="informatique" animationData={Code}>
          </Lottie>
          <Lottie className="electronique" animationData={ElectronicAnimation}>
          </Lottie>
        </ParallaxLayer>

        {/* + pour aller vers les projets */}
        <ParallaxLayer className="plus-button" offset={3} speed={0.2}>
          <div className="text">{t('Et plus')} </div>
          <PlusButton />
        </ParallaxLayer>

        <ParallaxLayer
          offset={3}
          speed={0.2}
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            zIndex: 6,
            height: "60px",
            marginTop: "75vh",
          }}
          onClick={() => {
            parallax.current.scrollTo(4);
          }}
        >
          <AnimatedArrow down={true} />
        </ParallaxLayer>

        {/* Page 5 */}
        {/* Les projets d'innovations d'aujourd'hui, par les ingénieurs de demain */}
        <ParallaxLayer offset={4} speed={0.1} className="page-5-pg-1">
          <h4 className="blur-text ">
            {t('Innovations')} 
            <br />
            <br />
            {t('Ingénieurs')} 
          </h4>
        </ParallaxLayer>

        {/* Nos 40 années d'expérience à votre service
          Les compétences de 8 départements d'ingénierie */}
        <ParallaxLayer offset={4} speed={0.4} className="page-5-pg-2">
          <h4 className="blur-text">
            {t('40 ans')} 
            <br />
            <br />
            {t('8 départs')} 
          </h4>
        </ParallaxLayer>

        {/* Une qualité professionnelle au bon prix
        L'une des 10 meilleures écoles d'ingénieurs post-bac */}
        <ParallaxLayer offset={4} speed={0.7} className="page-5-pg-3">
          <h4 className="blur-text">
            {t('Qualité')} 
            <br />
            <br />
            {t('Top 10')} 
          </h4>
        </ParallaxLayer>

        {/* Un accompagnement adapté à vos besoins
        Un campus orienté vers la recherche */}
        <ParallaxLayer offset={4} speed={1} className="page-5-pg-4">
          <h4 className="blur-text">
            {t('Accompagnement')} 
            <br />
            <br />
            {t('Campus')} 
          </h4>
        </ParallaxLayer>

        <ParallaxLayer
          offset={4}
          speed={0.2}
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            zIndex: 6,
            height: "60px",
            marginTop: "85vh",
          }}
          onClick={() => {
            parallax.current.scrollTo(5);
          }}
        >
          <AnimatedArrow down={true} />
        </ParallaxLayer>


        {/* Page 6 */}
        {/* Nous contacter */}
        <ParallaxLayer
          offset={5}
          speed={0.4}
          style={{
            display: "flex",
            zIndex: 5,
          }}
        >
          <img
            src={InsaFonce}
            alt="INSA Rennes"
            loading = "lazy"
            className="insa-fonce"
          />
        </ParallaxLayer>

        <ParallaxLayer
          offset={5}
          speed={0.1}
          style={{
            display: "flex",
            justifyContent: "center",
            zIndex: 5,
            marginTop: "15vh",
          }}
        >
          <ContactUsButton></ContactUsButton>
        </ParallaxLayer>

        <ParallaxLayer
          offset={5}
          speed={0.5}
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            zIndex: 6,
            height: "60px",
            marginTop: "60vh",
          }}
          onClick={() => {
            parallax.current.scrollTo(0);
          }}
        >
          <AnimatedArrow />
        </ParallaxLayer>

        <div className="footer">
          <MemoizedPageFooter />
        </div>
      </Parallax>
    </div>
  );
}
