import hexa_meca from './images/hexagones/hexa_meca.png';
import hexableu_meca from './images/hexagones/hexableu_meca.png';
import hexa_geniecivil from './images/hexagones/hexa_geniecivil.png';
import hexableu_geniecivil from './images/hexagones/hexableu_geniecivil.png';
import hexa_telecom from './images/hexagones/hexa_telecom.png';
import hexableu_telecom from './images/hexagones/hexableu_telecom.png';
import hexa_iot from './images/hexagones/hexa_iot.png';
import hexableu_iot from './images/hexagones/hexableu_iot.png';
import hexa_data from './images/hexagones/hexa_data.png';
import hexableu_data from './images/hexagones/hexableu_data.png';
import hexa_web from './images/hexagones/hexa_web.png';
import hexableu_web from './images/hexagones/hexableu_web.png';
import hexa_software from './images/hexagones/hexa_software.png';
import hexableu_software from './images/hexagones/hexableu_software.png';

export const images = {
    hexa_meca,
    hexableu_meca,
    hexa_geniecivil,
    hexableu_geniecivil,
    hexa_telecom,
    hexableu_telecom,
    hexa_iot,
    hexableu_iot,
    hexa_data,
    hexableu_data,
    hexa_web,
    hexableu_web,
    hexa_software,
    hexableu_software
};
