import React, { useState } from 'react';
import '../styles/components/Page-Footer.css';
import LogoOuestInsaNormal from "../images/Logo_fond_blanc.png";
import LogoOuestInsaClicked from "../images/Logo_fond_blanc_onclicked.png";
import { NavLink } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import instagram from "../images/logos/instagram.png";
import linkedin from "../images/logos/linkedin.png";

export default function PageFooter() {
    // state (état, données)
    const { t } = useTranslation('navbfoot');
    const [logoSrc, setLogoSrc] = useState(LogoOuestInsaNormal);

    const handleLogoMouseEnter = () => {
        setLogoSrc(LogoOuestInsaClicked);
    };

    const handleLogoMouseLeave = () => {
        setLogoSrc(LogoOuestInsaNormal);
    };

    // comportements

    // affichage (render)

  return(
    <div className='page-footer-container'>
        <div className='left-left-container'>
            {/* logo ouest insa */}
            <NavLink to='/'>
                <img
                src={logoSrc}
                alt="Logo ouest insa"
                className="nav-logo"
                onMouseEnter={handleLogoMouseEnter}
                onMouseLeave={handleLogoMouseLeave}
            />
            </NavLink>
        </div>
        <div className='left-container'>
            Contact
            <br></br>
            contact@ouest-insa.fr
            <br></br>
            +33 2 23 23 85 72
        </div>
        <div className='middle-container'>
            Ouest INSA
            <br></br>
            20 Avenue des Buttes de Coësmes
            <br></br>
            CS 70839 35708 Rennes Cedex 7

        </div>
        <div className='right-container'>
            {t('Réseaux')} :
            <br></br>
            <div className='social-container'>
                <a href="https://www.instagram.com/OuestINSA/" target="_blank" rel="noopener noreferrer">
                    <img src={instagram} className='socialpng' alt="instagram" />
                </a>
                <a href="https://fr.linkedin.com/company/ouest-insa-junior-entreprise" target="_blank" rel="noopener noreferrer">   
                    <img src={linkedin} className='socialpng' alt="linkedin"/>
                </a>  
            </div>
            </div>
    </div>
  );
}
