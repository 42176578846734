import React from "react";
import "../styles/pages/ContactUSStyles.css";
import { useTranslation } from "react-i18next";
import emailjs from "@emailjs/browser";
import { useEffect } from "react";


export default function ContactUsPage(props) {

  useEffect(() => emailjs.init("YUf7UAP3A2FESdUpy"), []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const serviceId = "service_39j7oag";
    const templateId = "template_kg0uezd";
    try {
      emailjs.send(serviceId, templateId, {
        first_name: document.getElementById("first_name").value,
        last_name: document.getElementById("last_name").value,
        phone: document.getElementById("phone").value,
        email: document.getElementById("email").value,
        message: document.getElementById("message").value,
      });
      alert("Email sent to Ouest INSA!");
    } catch (error) {
      console.log(error);
    }
  };

const { t } = useTranslation("navbfoot");
return (
  <div className="overlay" id="divOne">
    <div className="wrapper">
      <h2>{t("Contact")}</h2>
      <input placeholder={t("Prénom")} type="text" id="first_name"></input>
      <input placeholder={t("Nom")} type="text" id="last_name"></input>
      <div className="textareabit">
        <input placeholder={t("Téléphone")} type="text" id="phone"></input>
      </div>
      <input placeholder="Email *" type="text" id="email"></input>
      <div className="textareabit">
        <div className="handle-hide"></div>
        <textarea placeholder={t("Projet")} id="message"></textarea>
      </div>
      <div className="btn-field">
        <div className="button-div">
          <a href="#">
            <button type="button" className="disable">
              <span className="cancel">{t("Annuler")}</span>
            </button>
          </a>
        </div>
        <div className="button-div">
          <a href="#">
            <button type="button" onClick={handleSubmit}>{t("Soumettre")}</button>
          </a>
        </div>
      </div>
    </div>
  </div>
);
}
