import React from 'react';
import { useTranslation } from 'react-i18next';
import '../styles/pages/pageQSN.css';
import PageFooter from '../components/Page-Footer';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import mandat2021 from '../images/mandat/mandat2021.jpg';
import mandat2022 from '../images/mandat/mandat2022.jpg';
import mandat2023 from '../images/mandat/mandat2023.jpg';
import mandat2024 from '../images/mandat/mandat2024.jpg'
import insa from '../images/partenaires/insa.png';
import cnje from '../images/partenaires/cnje.png';
import jinsa from '../images/partenaires/jinsa.png';
import migso from '../images/partenaires/migso.png';
import bpgo from '../images/partenaires/bpgo.png';
import epnak from '../images/partenaires/epnak.png';

const PageQSN = () => {
  const { t } = useTranslation('qsn'); 

  return (
    <div>
      <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"></link>
      <br />
      <div className='qsn'>
        <div className='titre'>{t('quisommesnous')}</div>
        <br />
        <div className='carousel'>
        <Carousel
          centerMode={true}
          centerSlidePercentage={100}
          showArrows={true}
          showThumbs={false}
          showStatus={false}
          dynamicHeight={true}
          infiniteLoop={true}
          autoPlay={true}
          interval={3000}
          transitionTime={300}
          stopOnHover={true}
          swipeable={true}
          width={'100%'}
        >
          <div>
            <img className='imgmandat' src={mandat2024} alt ='Mandat 2024' />
            <p className="text legend">{t('mandat24')}</p>
          </div>
          <div>
            <img className='imgmandat' src={mandat2023} alt ='Mandat 2023' />
            <p className="text legend">{t('mandat23')}</p>
          </div>
          <div>
            <img className='imgmandat' src={mandat2022} alt='Mandat 2022' />
            <p className="text legend">{t('mandat22')}</p>
          </div>
          <div>
            <img className='imgmandat' src={mandat2021} alt='Mandat 2021' />  
            <p className="text legend">{t('mandat21')}</p>
          </div>
        </Carousel>
        </div>
        <br />
        <div className='noussommes'>{t('noussommes')}</div>
        <br />
        <div className='soustitre'>{t('soustitre_equipe')}</div>
        <div className='text'>{t('text_equipe')}</div>
        <br />
        <br />
        <div className='soustitre'>{t('soustitre_institution')}</div>
        <div className='text'>{t('text_institution')}</div>
        <br />
        <br />
        <div className='soustitre'>{t('soustitre_junior_entreprise')}</div>
        <div className='text'>{t('text_junior_entreprise')}</div>
        <br />
        <br />
        <div className='gridvaleurs'>
        <div className='dynamism'>
            <p className='value'>{t('dynamism_value')}</p>
            <i className='icone' class="fa fa-2x fa-paper-plane"></i>
            <p className='textvaleurs'>{t('dynamism_text')}</p>
          </div>
          <div className='disponibility'>
            <p className='value'>{t('disponibility_value')}</p>
            <i className='icone' class="fa fa-2x fa-calendar"></i>
            <p className='textvaleurs'>{t('disponibility_text')}</p>
          </div>
          <div className='quality'>
            <p className='value'>{t('quality_value')}</p>
            <i className='icone' class="fa fa-2x fa-search"></i>
            <p className='textvaleurs'>{t('quality_text')}</p>
          </div>
          <div className='following'>
            <p className='value'>{t('following_value')}</p>
            <i className='icone' class="fa fa-2x fa-comments"></i>
            <p className='textvaleurs'>{t('following_text')}</p>
          </div>
        </div>
        <br />
        <br />
        <div className='soustitre'>{t('soustitre_partenaires')}</div>
        <div className='gridpartenaires'>
        <img className='partenaire insa' src={insa} alt='INSA Rennes' onClick={() => window.open("https://www.insa-rennes.fr/", "_blank")}/>
        <img className='partenaire cnje' src={cnje} alt='CNJE' onClick={() => window.open("https://junior-entreprises.com/", "_blank")}/>
        <img className='partenaire jinsa' src={jinsa} alt='JINSA'/>
        <img className='partenaire migso' src={migso} alt='MIGSO' onClick={() => window.open("https://www.migso-pcubed.com/fr/#home", "_blank")}/>
        <img className='partenaire bpgo' src={bpgo} alt='BPGO' onClick={() => window.open("https://www.banquepopulaire.fr/bpgo/", "_blank")}/>
        <img className='partenaire epnak' src={epnak} alt='EPNAK' onClick={() => window.open("https://formation.epnak.org/rennes/", "_blank")}/>
      </div>  
      </div>
      <br />
      <br />
      <PageFooter></PageFooter>
    </div>
  );
}

export default PageQSN;
