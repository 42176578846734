import React, { startTransition } from 'react';
import { useState, useRef, useEffect } from 'react';
import PrestaButton from '../components/PrestaButton';
import '../styles/pages/PrestaPage.css';
import Domaine from '../components/Domaine';
import flecheDroite from '../images/fleche-droite.png';
import flecheGauche from '../images/fleche-gauche.png';
import flecheBas from '../images/fleche-bas.png';
import hexagone from '../images/hexagones/hexagoneActive.png';
import hexagoneBLEU from '../images/hexagones/hexagoneActive.png';

function debounce(fn, ms) {
    let timer
    return _ => {
      clearTimeout(timer)
      timer = setTimeout(_ => {
        timer = null
        fn.apply(this, arguments)
      }, ms)
    };
  }

export default function PrestaPage(props) {
    const [dimensions, setDimensions] = React.useState({ 
    height: window.innerHeight,
    width: window.innerWidth
  })
  React.useEffect(() => {
    const debouncedHandleResize = debounce(function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth
      })
    }, 10)

    window.addEventListener('resize', debouncedHandleResize)

    return _ => {
      window.removeEventListener('resize', debouncedHandleResize)
    }
  })
    
    const [prestaChosen, setPrestaChosen] = useState(false)
    const [idActive, setIdActive] = useState(0);
    const [position, setPosition] = useState([0,1,2,3,4,5,6])
    const [initial, setInitial] = useState(true);

    const downToPresta = useRef(null);
    useEffect(() => {
        downToPresta?.current?.scrollIntoView({
            behavior: "smooth"
        });
    });

    const BoolPrestaChosen = () => {
        startTransition (() => {
        setPrestaChosen(true);
        });
    } 


    const positionsConfig = [ //x au milieu et je suis y ==> mon state est positionsConfig[x][y] (id entre 1 et 7)
        // [5,6,7,1,2,3,4],=position
        [3,4,5,6,0,1,2],
        // [6,7,1,2,3,4,5],
        [2,3,4,5,6,0,1],
        // [7,1,2,3,4,5,6],
        [1,2,3,4,5,6,0],
        // [1,2,3,4,5,6,7],
        [0,1,2,3,4,5,6],
        // [2,3,4,5,6,7,1],
        [6,0,1,2,3,4,5],
        // [3,4,5,6,7,1,2],
        [5,6,0,1,2,3,4],
        // [4,5,6,7,1,2,3],
        [4,5,6,0,1,2,3],
    ]

    const updatePosition = (newIdActive1) => {
        startTransition (() => {
        setPosition(positionsConfig[newIdActive1-1]);
        setIdActive(newIdActive1);
        setInitial(false);
    });
}

    return (
        <div className={initial?'main-container initial':'main-container'}>
            {/* 2 br qui suivent à supprimer pour recadrer la page */}
            <br/>
            <br/>
            {dimensions.width>774?
                        <div className='top-container'> 
                        <div className={
                            idActive===1?'homepage gma':
                            idActive===2?'homepage gcu':
                            idActive===3?'homepage telecom':
                            idActive===4?'homepage iot':
                            idActive===5?'homepage devlog':
                            idActive===6?'homepage devweb':
                            idActive===7?'homepage data':''
                            }>
                            {props.domaines.map((domaine) => {
                                return <PrestaButton 
                                img={!initial && domaine.id===idActive?domaine.img:domaine.img} 
                                imgHover={domaine.imgHover}
                                nom={domaine.nom} id={domaine.id} 
                                state={position[domaine.id-1]} 
                                onClick={()=>updatePosition(domaine.id)} 
                                initial={initial} 
                                active={!initial && domaine.id===idActive}/>
                            })}
                            <div>
                                {props.domaines.map((domaine) => {
                                    if(!initial && domaine.id===idActive){
                                        return <div className='container'>
                                                <div className='colonne'>
                                                    <br></br>
                                                    <br></br>
                                                    <br></br>
                                                    <br></br>
                                                    <br></br>
                                                    <br></br>
                                                    <div ref={downToPresta} />
                                                    <button className='bouton' onClick={() => updatePosition(idActive-1===0?7:idActive-1)}>
                                                        <img className='precedent' src={flecheGauche} alt='fleche gauche'/>
                                                    </button>
                                                </div>
                                                <div className='colonne presta'>
                                                    <h1 className='presta-name'>{domaine.nom}</h1>
                                                    <div className='presta-button'>
                                                        <PrestaButton img={hexagone} imgHover={hexagoneBLEU} nom={domaine.nom} id={7} state={7} onClick={BoolPrestaChosen} initial={initial}/>
                                                    </div>
                                                </div>
                                                <div className='colonne'>
                                                    <br></br>
                                                    <br></br>
                                                    <br></br>
                                                    <br></br>
                                                    <br></br>
                                                    <br></br>
                                                    <div ref={downToPresta} />
                                                    <button className='bouton' onClick={() => updatePosition(idActive+1===8?1:idActive+1)}>
                                                        <img className='suivant' src={flecheDroite} alt='fleche droite'/>
                                                    </button>
                                            </div>
                                    </div>                        
                                    }
                                })}
                            </div>
                        </div>
                    </div>
            :
            <div className='top-container'>
            <div className={
                idActive===1?'homepage gma':
                idActive===2?'homepage gcu':
                idActive===3?'homepage telecom':
                idActive===4?'homepage iot':
                idActive===5?'homepage devlog':
                idActive===6?'homepage devweb':
                idActive===7?'homepage data':''
                }>
                {initial && 
                        <div className='homepage'>
                        <div className='container'>
                        <div className='colonne presta'>
                            <br></br>
                            <br></br>
                            <br></br>
                            <br></br>
                            <br></br>
                            <h1 className='presta-name'>En savoir plus</h1>
                            <button className='bouton' onClick={()=>updatePosition(1)}>
                                <img className='precedent' src={flecheBas} alt='fleche bas'/>
                            </button>
                        </div>
                        </div>
                        </div>}
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <div>
                    {props.domaines.map((domaine) => {
                        if(!initial && domaine.id===idActive){
                            return <div className='container'>
                                    <div className='colonne'>
                                        <div ref={downToPresta} />
                                        <button className='bouton' onClick={() => updatePosition(idActive-1===0?7:idActive-1)}>
                                            <img className='precedent' src={flecheGauche} alt='fleche gauche'/>
                                        </button>
                                    </div>
                                    <div className='colonne presta'>
                                        <h1 className='presta-name'>{domaine.nom}</h1>
                                        
                                        <button className='bouton' onClick={()=>setPrestaChosen(true)}>
                                            <img className='precedent' src={flecheBas} alt='fleche bas'/>
                                        </button>
                                    </div>
                                    <div className='colonne'>
                                        <div ref={downToPresta} />
                                        <button className='bouton' onClick={() => updatePosition(idActive+1===8?1:idActive+1)}>
                                            <img className='suivant' src={flecheDroite} alt='fleche droite'/>
                                        </button>
                                </div>
                        </div>                        
                        }
                    })}
                </div>
            </div>
         </div>}

         {prestaChosen &&
                        <div>
                            <br></br>
                            <br></br>
                            <Domaine domaines={props.domaines} domaine={props.domaines[idActive-1]} onClickTopPage={() => 
                                downToPresta?.current?.scrollIntoView({
                                behavior: "smooth"
                                })}/>
                        </div>}

        </div>
    );
};