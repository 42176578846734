import React from 'react';
import '../styles/components/domaine.css';
import PageFooter from './Page-Footer';
import ContactUsPage from '../pages/ContactUsPage';
import { useTranslation } from 'react-i18next';


export default function Domaine(props) {
    // state (état, données)
    const { t } = useTranslation('presta');
    // comportements

    // affichage (render)
    return(
        <div className='domaine-container'>
            {props.domaine.page}
            <br></br>
            <div className='buttons-container'>
            <br></br>
            <button className="btn-bottom-top" onClick={props.onClickTopPage}>{t("haut")}</button>
            </div>
            <br></br>
            <PageFooter></PageFooter>
            <ContactUsPage></ContactUsPage>
        </div>
    );
}