import React, { useState } from "react";
import { useRef, useEffect} from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import "../styles/components/Navbar.css";
import "../styles/pages/ContactUSStyles.css";
import LogoOuestInsaNormal from "../images/Logo_fond_blanc.png";
import ContactUsPage from "../pages/ContactUsPage.js";
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { startTransition } from "react";


// Lottie react-lottie
// '@lottiefiles/lottie-player'
// '@lottiefiles/lottie-interactivity'

export default function Navbar(props) {

  const { t, i18n } = useTranslation('navbfoot');

  const navRef = useRef();
  const [logoSrc] = useState(LogoOuestInsaNormal);
  const [hoveredImage, setHoveredImage] = useState(null);

  const showNavbar = () => {
    navRef.current.classList.toggle("responsive-nav");
  };

  const navigate = useNavigate();

  function navigateTo(url) {
    navigate(url);
  }

  function changeLanguage(lang) {
    localStorage.setItem('language', lang);
    i18n.changeLanguage(lang);
  }

  useEffect(() => {
    const storedLanguage = localStorage.getItem('language');
    if (storedLanguage) {
      changeLanguage(storedLanguage);
    }
  }, []);

  return (
    <div className="navbar-component">
      <header className="navbar-body">
        {/* logo ouest insa */}
        <img
    src={logoSrc}
    alt="Logo ouest insa"
    className="nav-logo"
    onMouseEnter={() => setHoveredImage(1)}
    onMouseLeave={() => setHoveredImage(null)}
    onClick={() => startTransition(() => navigateTo("/#"))}
    style={{
      filter: hoveredImage === 1 ? "grayscale(100%)" : "none",
    }}
  />
        <nav ref={navRef}>
          {/* Qui sommes nous ? */}
          <a href="/team" className="nav-container nav-text-displayed">
            {t('QSN')}
          </a>
          {/* Nos prestations */}
          <a href="/prestations" className="nav-container nav-text-displayed">
            {t('Presta')}
          </a>
          {/* Nous contacter */}
          <a href="#divOne" className="contact-us-button">
            <button className="nav-btn-nous-contacter ">{t('Contact')}</button>
          </a>
          {/* logo drapeau pour la langue */}
          {/* Français */}
          <button className="nav-btn-drapeau" onClick={() => startTransition(() => changeLanguage('fr'))}>🇫🇷</button>
          {/* logo drapeau pour la langue */}
          <button className="nav-btn-drapeau" onClick={() => startTransition(() => changeLanguage('en'))}>🇬🇧</button>
          {/* bouton lorsque on est en mode téléphone */}
          <button
            className="nav-btn nav-close-btn nav-container"
            onClick={showNavbar}
          >
            <FaTimes />
          </button>
        </nav>
        <button className="nav-btn nav-container" onClick={showNavbar}>
          <FaBars />
        </button>
      </header>

      {/* contact us page  */}
      <ContactUsPage></ContactUsPage>
    </div>
  );
}
